import React from 'react';

const Faq = () => {
    return (
        <section className="faq-one">
            <div className="container">
                <div className="row no-gutters">
                    <div className="col-lg-6">
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span>?</span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">How long are your
                                    contracts?</h2>
                                <p className="faq-one__text">We don't do contracts. You can cancel your monthly or
                                    annual
                                    subscription at any time from within your
                                    dashboard.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span>?</span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">How long are your
                                    contracts?</h2>
                                <p className="faq-one__text">We don't do contracts. You can cancel your monthly or
                                    annual
                                    subscription at any time from within your
                                    dashboard.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span>?</span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">How long are your
                                    contracts?</h2>
                                <p className="faq-one__text">We don't do contracts. You can cancel your monthly or
                                    annual
                                    subscription at any time from within your
                                    dashboard.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span>?</span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">How long are your
                                    contracts?</h2>
                                <p className="faq-one__text">We don't do contracts. You can cancel your monthly or
                                    annual
                                    subscription at any time from within your
                                    dashboard.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6" style={{paddingBottom: `0px`, borderBottom: `0px none`}}>
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span>?</span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">How long are your
                                    contracts?</h2>
                                <p className="faq-one__text">We don't do contracts. You can cancel your monthly or
                                    annual
                                    subscription at any time from within your
                                    dashboard.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6" style={{paddingBottom: `0px`, borderBottom: `0px none`}}>
                        <div className="faq-one__single">
                            <div className="faq-one__icon">
                                <span>?</span>
                            </div>
                            <div className="faq-one__content">
                                <h2 className="faq-one__title">How long are your
                                    contracts?</h2>
                                <p className="faq-one__text">We don't do contracts. You can cancel your monthly or
                                    annual
                                    subscription at any time from within your
                                    dashboard.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Faq;
